window.fohlio ||= {};

export const isDev = process.env.NODE_ENV === 'development';

// Service Metadata
export const serviceApp = 'fohlio';
export const serviceName = 'core-front';
export const serviceBuild = window.fohlio.core_front_build || process.env.SERVICE_BUILD;

// Parent Backend Metadata
export const backendGroup = window.fohlio.core_group || window.fohlio.service_group; // prod, stage, qa
export const backendEnv = window.fohlio.core_env || window.fohlio.service_env || window.fohlio.app_name; // us-east-1, demo, next, prev, main, develop, test01, test02, test03
export const backendProfile = window.fohlio.core_profile || window.fohlio.service_profile || window.fohlio.app_env; // production, staging, development
export const backendBuild = window.fohlio.core_build;

// Configuration
export const apiUrl = window.fohlio.api_url;
export const cableUrl = window.fohlio.cable_url;

// Dependencies
export const sentryDsn = window.fohlio.sentry_dsn;
export const googleFontsApiKey = window.fohlio.google_fonts_api_key;
export const recaptchaSiteKey = window.fohlio.recaptcha_site_key;
export const stripePublishableKey = window.fohlio.stripe_publishable_key;
export const gtmId = window.fohlio.gtm_id;
export const hubspotId = window.fohlio.hubspot_id;
export const totangoServiceId = window.fohlio.totango_service_id;
export const pendoId = window.fohlio.pendo_id;

// Micro Frontends
export const reactBaseUrl = window.fohlio.core_front_static_url || window.fohlio.react_base_url;
export const moodboardsBaseUrl = window.fohlio.moodboards_front_static_url || window.fohlio.moodboards_base_url;

// Deprecated
export const boldBaseUrl = null;
